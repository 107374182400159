<template>
  <v-card>
    <v-card-text class="pa-2">
      <form @submit.prevent="search"
            @keydown.ctrl.enter.capture.stop="search">
        <v-row no-gutters
               align="center"
               class="flex-nowrap">
          <v-col>
            <slot name="searchFields" />
          </v-col>
          <v-col class="flex-grow-0"
                 :class="{'text-no-wrap': singleLine}">
            <v-btn type="submit"
                   color="primary"
                   class="ma-1"
                   :loading="loading"
                   fab
                   small
                   dark>
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <trol-export-button
              v-if="exportFunction"
              :request-data-function="exportFunction" />
            <v-btn v-if="$listeners.resetSearch"
                   color="accent"
                   class="ma-1"
                   fab
                   small
                   dark
                   @click="resetSearch">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
          <v-col class="flex-grow-0">
            <slot name="afterControls" />
          </v-col>
        </v-row>
      </form>
      <v-btn v-if="canCreate"
             :to="$listeners.create ? undefined : createUrl ? createUrl : 'new'"
             color="success"
             style="z-index: 1"
             append
             fab
             absolute
             left
             bottom
             small
             @click="$listeners.create && $emit('create')">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>

import TrolExportButton from '@/components/Export/ExportButton';

export default {
  name: 'SearchLayout',
  components: {TrolExportButton},
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    createUrl: {
      type: [String, Object],
      default: undefined,
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
    exportFunction: {
      type: Function,
      default: undefined,
    },
  },
  methods: {
    resetSearch () {
      this.$emit('resetSearch');
    },
    search () {
      this.$emit('search');
    },
  },
};
</script>

<style>
</style>
