<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn
        :loading="loading"
        color="success"
        class="ma-1"
        fab
        small
        dark
        v-on="on">
        <v-icon>mdi-file-download-outline</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="exportData('xlsx')">
        <v-list-item-title>XLSX</v-list-item-title>
      </v-list-item>
      <v-list-item @click="exportData('csv')">
        <v-list-item-title>CSV</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>

import MQTT from '@/services/MQTT';
import {mapGetters, mapState} from 'vuex';

export default {
  name: 'TrolExportButton',
  props: {
    requestDataFunction: {
      type: Function,
      required: true,
    },
  },
  data () {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),
    ...mapState({
      auth: state => state.auth,
    }),
  },
  created () {
    MQTT.subscribe('export_completed/' + this.$store.state.auth.contact.id, (data) => {
      data.links.export_file.link += '?bearer=' + this.auth.token;
      this.$store.dispatch('snackbar/add', {
        content: data.template,
        fallback: data.template,
        links: data.links,
        icon: 'mdi-file-download-outline',
        iconColor: 'success',
        timeout: 30000,
      });
    });
  },
  methods: {
    async exportData (format) {
      const requestData  = this.requestDataFunction();
      requestData.format = format;
      this.loading       = true;
      try {
        const response = await this.API.post.progress(['export', 'add-to-queue'], requestData);
        this.$store.dispatch('snackbar/add', {
          content: response.data.template,
          fallback: response.data.template,
          links: response.data.links,
          icon: 'mdi-information-outline',
          iconColor: 'success',
          timeout: 10000,
        });
        this.loading = false;
      } catch (exception) {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
